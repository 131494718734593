import { animated } from '@react-spring/web'
import classNames from 'classnames'
import keycode from 'keycode'
import type { FunctionComponent, HTMLAttributes, ReactNode } from 'react'
import { useState } from 'react'

import { useAnchorRef } from '../../lib/hooks/useAnchorRef'
import { useDropdownAnimation } from '../../lib/hooks/useAnimation'
import { tracker } from '../../lib/store/tracker/useTracker'
import { Paragraph } from '../paragraph'
import { MinusIcon, PlusIcon } from '../svgs'
import { Text } from '../text'
import styles from './details.module.scss'

/** HTML5-inspired expandable container, an accordion, if you will.
 * Accepts most of `<details>` element props, plus `summary`.
 * Stays open in HTML markup to allow for open/close animation.
 */
export const Details: FunctionComponent<React.PropsWithChildren<Props>> = ({
    children,
    summary,
    loading,
    anchor,
    ...restOfProps
}) => {
    const [isExpanded, setExpanded] = useState(false)
    const [contentRef, wrapperStyle] = useDropdownAnimation(isExpanded)

    const ref = useAnchorRef(anchor, () => {
        setExpanded(false)
    })

    return (
        <details
            open={isExpanded}
            ref={ref}
            id={anchor}
            tabIndex={0}
            onKeyDown={(e) => {
                if (loading) return
                if (keycode(e as any) === 'enter') {
                    e.preventDefault()
                    setExpanded(!isExpanded)

                    if (typeof anchor === 'string') {
                        tracker.click('faq', anchor, { open: !isExpanded })
                    }
                }
            }}
            aria-expanded={isExpanded}
            className={classNames(styles.details, {
                [styles['scroll-anchor']]: anchor,
            })}
            {...restOfProps}
        >
            <summary
                tabIndex={-1}
                className={styles.summary}
                onClick={(e) => {
                    e.preventDefault()
                    if (loading) return
                    setExpanded(!isExpanded)

                    if (typeof anchor === 'string') {
                        tracker.click('faq', anchor, { open: !isExpanded })
                    }
                }}
            >
                <div className={styles['summary-content']}>
                    <div
                        className={classNames(styles['summary-text'], {
                            [styles['summary-text-loading']]: loading,
                        })}
                    >
                        {loading ? (
                            <Paragraph loading numberOfLines={1} />
                        ) : (
                            <Text typeStyle='body-lg' tag='div' bold>
                                {summary}
                            </Text>
                        )}
                    </div>

                    <div className={styles['summary-icon']}>
                        {isExpanded ? <MinusIcon /> : <PlusIcon />}
                    </div>
                </div>
            </summary>

            <animated.div className={styles.content} style={wrapperStyle}>
                <div ref={contentRef} className={styles.text}>
                    {loading ? (
                        <Paragraph loading />
                    ) : (
                        <Text color='dim' typeStyle='body-sm' tag='div'>
                            {children}
                        </Text>
                    )}
                </div>
            </animated.div>
        </details>
    )
}

interface Props extends HTMLAttributes<HTMLDetailsElement> {
    /** Short preview, always visible and clickable */
    summary?: ReactNode
    /** Is this open on mount? */
    initialOpen?: boolean
    /** Is content loading? */
    loading?: boolean
    /** Link within a page. */
    anchor?: string
}
