import produce from 'immer'

import { usePersonalizations } from '../../lib/hooks/usePersonalizations'
import { renderTemplate } from '../../lib/utils/text'
import { makeUseQueryHook } from '../functions'
import { EMPTY_SECTION_OF_FAQS } from '../placeholders'
import { GetSectionOfFaQsDocument } from '../queries/GetSectionOfFAQs.graphql'

/**
 * Returns the personalized content for a section.
 */
export const useSectionOfFAQs = (id: string) => {
    const personalizations = usePersonalizations()
    const result = useGetSectionOfFAQs({ variables: { id } })

    const section = produce(
        result.data?.section ?? EMPTY_SECTION_OF_FAQS,
        (draft) => {
            const { section, faqs } = draft

            if (section) {
                section.title = renderTemplate(section.title, personalizations)
                section.subtitle = renderTemplate(
                    section.subtitle,
                    personalizations
                )
            }

            if (!faqs) return

            for (const item of faqs.items) {
                if (!item) continue
                item.question = renderTemplate(item.question, personalizations)
                item.answer = renderTemplate(item.answer, personalizations)
            }
        }
    )

    return { section, ...result }
}

const useGetSectionOfFAQs = makeUseQueryHook({
    query: GetSectionOfFaQsDocument,
})
